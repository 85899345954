
import { defineComponent, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import JwtService from '@/core/services/JwtService';
import { VueCookieNext } from 'vue-cookie-next';
import ApiService from '@/core/services/ApiService';
import * as Yup from 'yup';
import { __values } from 'tslib';

export default defineComponent({
  name: 'sign-in',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  mounted() {
    const store = useStore();
    const router = useRouter();
    this.getMessageData();
    if (JwtService.getToken()) {
      store.commit('setLoginData', VueCookieNext.getCookie('_seip_user'));
      router.push({ name: 'dashboard' });
    }
  },
  data() {
    return {
      passwordFieldType: 'password',
      message: [],
      show: false,
      load: true,
      appType: '' as any,
      userId: '' as any,
    };
  },
  async created() {
    let today = new Date().toISOString().slice(0, 10);
    if (today <= '2023-03-09') {
      if (VueCookieNext.getCookie('women_popup') == 1) {
        this.show = false;
      } else {
        this.show = true;
      }
    }
    setTimeout(() => (this.show = false), 10000);
    // sso handeling param from browser  app_type and responsible_user_id

    const urlParams = new URLSearchParams(window.location.search);
    const paramsBase64 = urlParams.get('params');
    if (paramsBase64) {
      const decodedParams = atob(paramsBase64);
      const paramsObj = JSON.parse(decodedParams);
      this.appType = paramsObj.app_type;
      this.userId = paramsObj.responsible_user_id;
    } else {
      this.appType = null;
      this.userId = null;
      console.log('No parameters found in URL');
    }

    if (this.appType != null && this.userId != null) {
      await this.checkSSOLogin(this.appType, this.userId);
    }
  },
  methods: {
    async checkSSOLogin(app_type, userId) {
      let formData = new FormData();
      const user_agent = navigator.userAgent;
      fetch('https://api.ipify.org?format=json')
        .then((x) => x.json())
        .then(({ ip }) => {
          let user_ip = ip;
          formData.set('user_ip', user_ip);
        });

      const appTypeMap = {
        finman: 1,
        minierp: 2,
      };
      const appType = appTypeMap[app_type];
      formData.set('app_type', app_type);
      formData.set('user_id', userId);
      formData.set('user_agent', user_agent);
      const store = useStore();
      const router = useRouter();
      store.dispatch(Actions.SSO, formData).then((response) => {
        if (VueCookieNext.getCookie('_institute_info_id') == 'null') {
          router.push({ name: 'dashboard' });
        } else {
          router.push({ name: 'sign-switch', params: { switch: 1 } });
        }
      });
    },
    async hide() {
      this.show = false;
      VueCookieNext.setCookie('women_popup', '1');
    },
    tooglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async getMessageData() {
      await ApiService.get('trainee/daily_msg')
        .then((response) => {
          this.message = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const login = Yup.object().shape({
      username: Yup.string().required().label('username'),
      password: Yup.string().min(6).required().label('Password'),
    });

    const onSubmitLogin = (values) => {
      values['user_agent'] = navigator.userAgent;
      fetch('https://api.ipify.org?format=json')
        .then((x) => x.json())
        .then(({ ip }) => {
          values['user_ip'] = ip;
        });

      store.dispatch(Actions.LOGOUT);
      if (submitButton.value) {
        submitButton.value.setAttribute('data-kt-indicator', 'on');
      }
      setTimeout(() => {
        store
          .dispatch(Actions.LOGIN, values)
          .then((response) => {
            if (VueCookieNext.getCookie('_institute_info_id') == 'null') {
              router.push({ name: 'dashboard' });
            } else {
              router.push({ name: 'sign-switch', params: { switch: 1 } });
            }
          })
          .catch(() => {
            // Swal.fire({
            //   text: "Wrong Username or Password",
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "Try again",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-danger",
            //   },
            // });
          });
        submitButton.value?.removeAttribute('data-kt-indicator');
      }, 2000);
    };
    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
